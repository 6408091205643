@import '../base.css';

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1500px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: var(--background-primary);
    /* background: #0F172A; */
    overflow: hidden;
    z-index: -1; 
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 34s linear infinite;
}

.background li:nth-child(0) {
    left: 67%;
    width: 46px;
    height: 46px;
    bottom: -46px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 74%;
    width: 25px;
    height: 25px;
    bottom: -25px;
    animation-delay: 1s;
}
.background li:nth-child(2) {
    left: 37%;
    width: 42px;
    height: 42px;
    bottom: -42px;
    animation-delay: 5s;
}
.background li:nth-child(3) {
    left: 15%;
    width: 52px;
    height: 52px;
    bottom: -52px;
    animation-delay: 8s;
}
.background li:nth-child(4) {
    left: 52%;
    width: 32px;
    height: 32px;
    bottom: -32px;
    animation-delay: 4s;
}
.background li:nth-child(5) {
    left: 84%;
    width: 67px;
    height: 67px;
    bottom: -67px;
    animation-delay: 19s;
}
.background li:nth-child(6) {
    left: 72%;
    width: 34px;
    height: 34px;
    bottom: -34px;
    animation-delay: 26s;
}
.background li:nth-child(7) {
    left: 55%;
    width: 52px;
    height: 52px;
    bottom: -52px;
    animation-delay: 34s;
}
.background li:nth-child(8) {
    left: 77%;
    width: 41px;
    height: 41px;
    bottom: -41px;
    animation-delay: 34s;
}
.background li:nth-child(9) {
    left: 40%;
    width: 44px;
    height: 44px;
    bottom: -44px;
    animation-delay: 30s;
}
.background li:nth-child(10) {
    left: 87%;
    width: 38px;
    height: 38px;
    bottom: -38px;
    animation-delay: 46s;
}
.background li:nth-child(11) {
    left: 58%;
    width: 38px;
    height: 38px;
    bottom: -38px;
    animation-delay: 38s;
}
.background li:nth-child(12) {
    left: 68%;
    width: 40px;
    height: 40px;
    bottom: -40px;
    animation-delay: 27s;
}
.background li:nth-child(13) {
    left: 63%;
    width: 25px;
    height: 25px;
    bottom: -25px;
    animation-delay: 36s;
}
.background li:nth-child(14) {
    left: 64%;
    width: 64px;
    height: 64px;
    bottom: -64px;
    animation-delay: 34s;
}
.background li:nth-child(15) {
    left: 80%;
    width: 49px;
    height: 49px;
    bottom: -49px;
    animation-delay: 38s;
}
.background li:nth-child(16) {
    left: 85%;
    width: 2px;
    height: 2px;
    bottom: -2px;
    animation-delay: 62s;
}
.background li:nth-child(17) {
    left: 4%;
    width: 16px;
    height: 16px;
    bottom: -16px;
    animation-delay: 64s;
}
.background li:nth-child(18) {
    left: 83%;
    width: 20px;
    height: 20px;
    bottom: -20px;
    animation-delay: 30s;
}
.background li:nth-child(19) {
    left: 59%;
    width: 45px;
    height: 45px;
    bottom: -45px;
    animation-delay: 50s;
}
.background li:nth-child(20) {
    left: 85%;
    width: 20px;
    height: 20px;
    bottom: -20px;
    animation-delay: 44s;
}
.background li:nth-child(21) {
    left: 12%;
    width: 29px;
    height: 29px;
    bottom: -29px;
    animation-delay: 45s;
}
.background li:nth-child(22) {
    left: 31%;
    width: 54px;
    height: 54px;
    bottom: -54px;
    animation-delay: 25s;
}
.background li:nth-child(23) {
    left: 81%;
    width: 44px;
    height: 44px;
    bottom: -44px;
    animation-delay: 4s;
}