@import '../base.css';

.button-outliner {
    border: 1px solid var(--dark-sky-blue);
    border-color: var(--dark-sky-blue);
    color: white;
}

.button-outliner:hover {
    border: 1px solid #2c87c7; 
}

.button-action {
    border: 1px solid var(--dark-sky-blue);
    border-color: var(--dark-sky-blue);
    background-color: rgba(38, 122, 182, 0.1); 
    color: var(--dark-sky-blue);
    font-weight: bold;
    border-radius: 20px; 
}

.button-send-recive {
    border: 1px solid var(--yellow-orange);
    color: var(--yellow-orange);
    border-radius: 20px;
    background-color: rgba(255, 179, 0, 0.1);
    width: 9rem;
}

.button-action:hover {
    background-color: rgba(38, 122, 182, 0.5); 
    color: white;
    transform: scale(102%);
}

.button-action-off {
    border: 1px solid #6c6d72;
    border-color: #6c6d72;
    background-color: #6c6d72; 
    color: white;
}

.button-action-off:hover {
    background-color: #8a8b92; 
    border: 1px solid #8a8b92; 
}

.button-send-recive:hover {
    color: white;
    border-radius: 20px;
    background-color: rgba(255, 179, 0, 0.5); 
    transform: scale(102%);
}

.button-send-recive:hover i {
    color: white;
}