body {
    /* 'Lato', sans-serif,  */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;

    width: 100%;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

:root {
    --indigo: #380574;
    --purply-blue: #7908ff;
    --pale-lilac: #ececf0;
    --light-periwinkle: #d8d9e2;
    --white: #ffffff;
    --pink-red: #A93226;
    --pink: #f5afcb;
    --light-pink: #f8f3ff;
    /* --dark-sky-blue: #309be5; */
    --dark-sky-blue: #267ab6;
    --charcoal-grey: #373744;
    --bluey-grey: #a5a7af;
    --aqua-blue: #01c9e8;
    --aqua-green: #196F3D;
    --yellow-orange: #ffb300;
    /* --background-primary: rgb(15, 31, 40); */
    /* --background-primary: #011a27; */
    /* --background-primary: #000C1B; */
    /* --background-primary: #292a2d; */
    /* --background-primary: rgb(32, 33, 36); */
    --background-primary: #161616;
}