@import './base.css';


/*  BACKGROUND  */

.purply-blue {
    background-color: var(--purply-blue);
}

.indigo {
    background-color: var(--indigo);
}

.pale-lilac {
    background-color: var(--pale-lilac);
}

.charcoal-grey {
    background-color: var(--charcoal-grey);
}

.dark-sky-blue {
    background-color:  var(--dark-sky-blue);
}


.aqua-blue {
    background-color:  var(--aqua-blue);
}

/* COLORS */

.font-title {
    font-family: 'Druk Wide Bold',  sans-serif;
    font-weight: 700;
}

/* .font-title {
    font-family: 'Druk Wide Bold',  sans-serif;
} */

.font-subtitle {
    font-family: 'Montserrat';
    font-weight: 700;
}

.font-text {
    font-family: 'Montserrat';
}

.color-dark-sky-blue {
    color: var(--dark-sky-blue);
}

.color-charcoal-grey {
    color: var(--charcoal-grey);
}

.color-purply-blue {
    color: var(--purply-blue);
}

.color-yellow-orange {
    color: var(--yellow-orange);
}

.color-aqua-green {
    color: var(--aqua-green);
}

.color-pink-red {
    color: var(--pink-red);
}

.card-secondary {
    /* background-color: #1a4564; */
    background-color: #1a4564;
}

.text-secondary {
    color: #1a4564;
}

.border-yellow-color {
    border-color: var(--yellow-orange);
}

.background-yellow {
    background: #97772a;
}

.background-blue {
    background: #267ab6;
}

.background-gray {
    background: #373744;
}

.background-primary {
    /* background: rgb(15, 31, 40); */
    /* background: rgb(32, 33, 238); */
    background: #021B3B;
}


/* SWEET ALERT */

.swal2-progress-step {
    font-size: 10px; /* Cambia el tamaño de la fuente */
}

.swal2-progress-step-line {
    background: rgb(55, 65, 81) !important;
}

.swal2-progress-step {
    background: rgb(55, 65, 81) !important;
}

.swal2-active-progress-step {
    background: rgb(76, 109, 158) !important;
}

.swal2-title {
    padding: 5px;
}

.confirm-button {
    border: 1px solid var(--dark-sky-blue) !important;
    border-color: var(--dark-sky-blue) !important;
    background-color: var(--dark-sky-blue) !important; 
    color: white !important;
}

.confirm-button:hover {
    background-color: #2c87c7 !important; 
    border: 1px solid #2c87c7 !important; 
}

.swal2-popup {
    border-radius: 15px !important; 
    border: 1px solid rgb(55, 65, 81) !important;
}

/* SWEET ALERT */


.modal-background {
    background: rgba(25, 25, 26, 0.75);
}

.modal-input {
    background: #2e2e2e !important;
}

.modal-container {
    border-radius: 15px;
    border: 1px solid #161616;
    background: #2e2e2e;
}

.check-box {
    color: var(--dark-sky-blue) !important;
}

.card-color {
    background: #323232;
}

/* WALLET */

.card-amount-wallet {
    border: 1px solid var(--dark-sky-blue);
    background-color: rgba(38, 122, 182, 0.5); 
    color: white;
} 